.filecard {
    display: flex;
    gap:10px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

.filetype{
    padding: 10px;
    height: auto;
    background-color: lightblue;
    color: black;
    font-weight: 700;
}


.filename{
    height: auto;
    color: black;
    font-size: larger;
    font-weight: 700;
    padding-right: 10px;
}


.projectdiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

.singleproject {
    display: grid;
    grid-template-columns: 25% 70% 5%;
    border: 1px solid lightgray;
    border-radius: 10px;
    cursor: pointer;
}

.singleproject img {
    object-fit: cover;
    height: 110px;
    width: 130px;
    border-radius: 10px 0px 0px 10px;
}

.singleproject .content_div {
    margin-top: 10px;
    padding: 0 10px;
}

.content_div p {
    font-weight: 600;
}

.cat_name_label {
    color: #34a108;
    font-weight: 600;
}

.bold_name {
    font-weight: bolder;
    color: black;
}

.jobviewimg {
    height: 310px;
    width: 100%;
    object-fit: cover;
}

.profile_img {
    width: 90px;
    height: 85px;
    border-radius: 50%;
    border: 2px solid #90D01F;
}

.provider_img {
    width: 120px;
    height: 115px;
    border-radius: 50%;
}

.review_item {
    background-color: white;
    border: 2px solid lightgray;
    border-radius: 5px;
    padding: 1rem;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    align-items: center;
    cursor: pointer;
}

.review_item_provider {
    background-color: white;
    padding: 1rem;
    display: grid;
    grid-template-columns: 25% 50% 25%;
}

.star_icon {
    color: #FFAB3D;
}

.code {
    color: #90D01F;
    font-weight: bold;
}

.response_div {
    text-align: center;
    background-color: lightgray;
    padding: 2rem;
}

.response_div_new {
    background-color: lightgray;
    padding: 5px;
    font-weight: bold;
}

.review_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

.ant-modal-footer {
    display: none;
}

.rejbtn {
    background-color: white;
    border: 1px solid #90D01F;
    width: 35%;
    color: black;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.accbtn {
    background-color: #90D01F;
    border: 1px solid #90D01F;
    width: 35%;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.new_modal_provider .ant-modal-close {
    display: block;
}

.button_group {
    display: flex;
    justify-content: space-between;
}

.onlinelabel {
    padding: 0px 6px;
    border-radius: 5px;
    background-color: #90d01f70;
    color: black;
    margin-top: 10px;
}

.chatbtn {
    background-color: #90D01F;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
}

.online_icon {
    font-size: 26px;
    color: #90D01F;
}

.itemlist {
    display: flex;
    grid-gap: 1rem;
}

.viewallBtn {
    background-color: white;
    border: 1px solid #90D01F;
    color: #90D01F;
    padding: 10px;
    width: 35%;
    font-weight: 600;
    border-radius: 8px;
}

.new_modal_provider {
    width: 55% !important;
}

.review_item_provider h6 {
    color: black;
}

.location_div {
    display: flex;
    justify-content: space-between;
}

.statuslabel {
    color: red;
    font-weight: 600;
}

.locationicon {
    background: #0EA008;
    padding: 2px 8px 6px 8px;
    margin: 5px;
    border-radius: 50%;
    font-size: 18px;
    color: white;
    cursor: pointer;
}

.locationtage {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 9px;
}

.createbtn {
    background-color: #90D01F;
    border: 1px solid #90D01F;
    width: 15%;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}

button {
    cursor: pointer;
}

.charcard {
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 0.8rem;
    align-items: center;
}

.charcard_active {
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 0.8rem;
    align-items: center;
    background-color: lightgray;
}

hr {
    margin: 0px !important;
}

.msgdisplay {
    color: #847a7a;
}

.chatprofile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #90D01F;
}

.chatViewprofile {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #90D01F;
}

.checkread {
    color: #90D01F;
}

.card {
    border-radius: 10px;
}

.chatheader {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
    width: 100%;
}

.chathead {
    display: flex;
    padding: 0.5rem;
    align-items: center;
}
.message_body
{
    display: flex;
}
.message_body.right-message
{
    justify-content: end;
}
.left-message .message_inner
{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.right-message .message_inner
{
    display: flex;
    flex-direction: column;
    align-items: end;
}
.message_body .message_inner
{
    width: 80%;
    padding:5px;
}
.message_body.right-message .message_inner p
{
    border:1px solid black;
    padding: 5px;
    border-radius: 5px;
}
.message_body.left-message .message_inner p
{
    border: 1px solid lightgrey;
    background: lightgrey;
    padding: 5px;
    border-radius: 5px;
}
.message_body .message_inner p
{
    margin:0px;
}
.message_body .message_inner span
{
    font-size:10px;
    color:grey;
}


.closeicon {
    font-size: 25px;
    margin-right: 1rem;
    cursor: pointer;
}

.messageplace {
    height: calc(100vh - 275px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

  
.addfileicon {
    font-size: 25px;
    cursor: pointer;
}

.charcard {
    cursor: pointer;
}

.messageinput {
    border: none;
    width: 100%;
    cursor: auto;
}


.sendbtn {
    background-color: #90D01F;
    color: white;
    border: none;
    padding: 2px 15px;
    border-radius: 5px;
    margin-right: 1rem;
}

.userlistdiv {
    border-radius: 10px;
    height: calc(100vh - 165px);
    overflow-y: auto;
}
.userlistdiv .inbox_body.active_inbox
{
    border-left:3px solid green;
}
.userlistdiv .inbox_body
{
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
}
.userlistdiv .inbox_body:hover
{
    background-color: #f3f3f3;
}
.userlistdiv .inbox_body .inbox_inner
{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.imageview {
    padding-top: 5rem !important;
}
.image_view{
    width: 100%;
}
.new_modal_new .anticon.anticon-close {
    font-size: 1em;
    background: #07cb791f;
    padding: 10px;
    border-radius: 50px;
    color: #34a108;
}
.new_modal_new{
    border-radius: 10px;
}

@media only screen and (max-width: 700px) {
    .projectdiv {
        display: block;
        grid-gap: 2rem;
    }

    .singleproject {
        margin-top: 20px;
        width: 100%;
        height: 90px;
        grid-template-columns: 30% 70%;
    }

    .singleproject img {
        height: 90px;
        width: 90px;
    }

    .bold_name {
        font-weight: bolder;
        color: black;
        font-size: 17px;
    }

    .cat_name_label {
        font-size: 17px;
    }
}