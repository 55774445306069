@font-face {
  font-family: 'Muli';
  src: url('./fonts/muli.regular.ttf');
}

.maiandra_font {
  font-family: 'Muli', cursive !important;
  font-size: 35px;
  font-weight: bold;
}

.text-truncate {
  font-size: 20px;
  color: gray !important;
  font-family: 'Muli';
  src: url('./fonts/muli.regular.ttf');
}

.featured_categorytext {
  font-family: 'Muli', cursive !important;
  font-size: 29px;
  font-weight: bold;
}

.featured_category {
  background-color: white;
}

.newheadingclass,
.addnew_add {
  font-family: 'Muli', cursive !important;
  font-size: 17px !important;
  font-weight: bold;
}

.newheadingclassbig {
  font-family: 'Muli', cursive !important;
  font-size: 23px !important;
  font-weight: bold;
}

address {
  font-family: 'Muli' !important;
  src: url('./fonts/muli.regular.ttf') !important;
  font-size: 18px !important;
}

.f_30 {
  font-size: 29px;
  font-family: 'Muli' !important;
  src: url('./fonts/muli.regular.ttf') !important;
  font-weight: bold !important;
}

/* @font-face {
  font-family: 'maiandragd';
  src: url('./fonts/Muli.ttf');
}

@font-face {
  font-family: 'lato';
  src: url('./fonts/Muli.ttf');
}

@font-face {
  font-family: 'quicksand';
  src: url('./fonts/Muli.ttf');
}

@font-face {
  font-family: 'quicksand_bold';
  src: url('./fonts/Muli.ttf');
}

@font-face {
  font-family: 'calibri_bold';
  src: url('./fonts/Muli.ttf');
}

body {
  margin: 0;
  font-family: 'muli' !important;
} */

/* .maiandra_font
{
  font-family: 'maiandragd', cursive !important;
}
.lato_font
{
  font-family: 'lato', cursive !important;
}
.quicksand_font_bold
{
  font-family: 'quicksand_bold', cursive !important;  
}
.calibri_font_bold
{
  font-family: 'calibri_bold', cursive !important;  
}
.quicksand_font
{
  font-family: 'quicksand', cursive !important;
}
code {
  font-family: 'SEGOEUI', cursive;
} */