@import "~react-image-gallery/styles/scss/image-gallery.scss";
$primarycolor: #34a108;
$light_grey: #e6e6e6;
$drak_grey: gray;
$primaryBlueColor: #170b59;
// $primarycolor: #34a108;
.primary_color,
.text-success {
    color: $primarycolor !important;
}

.primary_blue_color {
    color: $primaryBlueColor !important;
}
.primary_bg_blue_color {
    background-color: $primaryBlueColor !important;
}
.bg_light_pink {
    background-color: #fef5fe !important;
}
.bg_light_gray {
    background-color: #f5f5f8 !important;
}

.out_service_img {
    height: 145px !important;
    width: -webkit-fill-available;
}
.professional_service_img {
    height: 200px !important;
    width: -webkit-fill-available;
}

.choose_page_img {
    background-color: #dfdfdf;
    padding: 25px;
    border-radius: 100px;
    img {
        height: 75px;
        width: 75px;
    }
}

.green_border {
    border: 1px solid $primarycolor !important;
}
.blue_border {
    border: 1px solid $primaryBlueColor !important;
}
.how_icon_img {
    position: absolute;
    top: -18px;
    left: -8px;
    background: #fef5fe;
    border-radius: 30px;
}
.banner_imgae {
    height: 200px !important;
    width: 160px;
}

.c2Link {
    height: 170px !important;
    width: 240px;
    position: absolute;
    right: 200px;
}
@media (max-width: 1023px) and(orientation: landscape) {
    .froms {
        height: auto !important;
        padding: 3em !important;
    }
}

.br_14 {
    border-radius: 14px !important;
}
.br_right_50 {
    border-top-right-radius: 50px !important;
    border-top-left-radius: 15px !important;
}

.input_border {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    background-color: #ffffff !important;
}

.input_border .ant-input {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    background-color: #ffffff !important;
}

.ant-input:focus {
    border-color: #e9e9ea;
    outline: none !important;
    box-shadow: none !important;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.mw-450 {
    max-width: 450px;
}

.place {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    left: -10px;
    top: -10px;
    border: 1px solid blue;
    background: red;
    color: yellow;
}

.a_hover {
    color: #555 !important;
}

.clearfix .ant-upload-list-picture-card .ant-upload-list-item {
    height: 100px !important;
}

.clearfix .ant-upload-list {
    min-height: 230px;
    overflow: auto;
    max-height: 280px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px #fcfcfc;
}

.upload_img .ant-modal-footer {
    display: none;
}

.clearfix .ant-upload-select-picture-card {
    width: 1em;
    height: 1em;
    float: none !important;
    margin: 2em auto;
}
.btnn {
    margin: 0em 0em 1em 16em !important;
    float: none !important;
    width: 5em !important;
    height: 2rem !important;
}

.ant-select-auto-complete.ant-select .ant-input {
    border: 0px !important;
}

#canvas_1,
#canvas_2,
#canvas_3 {
    width: 100% !important;
}

.cursor_point {
    cursor: pointer;
}

.booking_view .ant-card-body {
    width: 100vw;
}

.booking_view .ant-card-head {
    width: 100vw;
}

.booking_view_showdow {
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px;
}

.img_fit {
    object-fit: contain;
}

.img_cover {
    vertical-align: middle;
    border-style: none;
    object-fit: cover;
}

.max_height_25 {
    max-height: 25em;
}

.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
}

.certain-search-item-count {
    position: absolute;
    color: #999;
    right: 16px;
}

.certain-category-search.ant-select-focused .certain-category-icon {
    color: $primaryBlueColor;
}

.avatar_shadow {
    box-shadow: 0px 0px 0px 3px $primarycolor;
}

span.ant-form-item-children.d-block {
    display: block;
}
.map_modal {
    min-height: 40vh;
    overflow: auto;
    border: 1px solid #beefd9;
    border-radius: 0 0 40px 40px;
}
.maps_modal {
    min-height: 30vh;
    overflow: auto;
    border: 1px solid #beefd9;
    border-radius: 0 0 40px 40px;
}
.maping .ant-modal-footer {
    padding: 40px 25px;
    text-align: center;
}

.no_color {
    color: #292931 !important;
}

@media only screen and (max-width: 600px) {
    .description_page .filler {
        background: rgba(197, 197, 197, 0.1) !important;
        border: 0;
        height: 0px;
        cursor: pointer;
        line-height: 2.4;
        color: #272727;
        border-radius: 10px;
        margin-right: 10px;
        text-align: left;
        position: relative;
        top: 0px;
        /* font-size: 2em; */
        padding: 0px 15px;
        margin-bottom: 15px;
        display: inline-table;
    }
    .dynamic_description {
        font-size: 10px;
        padding: 0px 25px;
    }
    .description_page .location_btn {
        background: rgba(197, 197, 197, 0.1) !important;
        border: 0 !important;
        height: 44px;
        width: 100% !important;
        line-height: 1;
        font-size: 10px;
        color: #272727;
        border-radius: 10px;
        margin-right: 10px;
        text-align: left;
        margin: 13px 0px;
        overflow: hidden;
    }
}

@media only screen and (min-width: 900px) {
    .otp_align {
        margin-left: 85px;
    }
}

.ant-list-bordered .ant-list-item {
    padding-right: 0px !important;
    padding-left: 8px !important;
}

// .dynamic_description label{
//     display: contents;
// }

li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
    background: $light_grey;
}

.ant-menu-submenu-title {
    margin: 0px !important;
}

.ant-timeline-item-head-green {
    color: $primarycolor;
    border-color: $primarycolor !important;
}
.ant-timeline-item-head-gray {
    color: $drak_grey;
    border-color: $drak_grey !important;
}

.ant-timeline-item-head {
    border: 3px solid transparent;
}
.ant-timeline-item-tail {
    border-left: 2px solid $light_grey !important ;
}
.tail_green {
    .ant-timeline-item-tail {
        border-left: 2px solid $primarycolor !important ;
    }
}
.upload_content .ant-upload-list.ant-upload-list-picture-card:after {
    content: "Click + to add images";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 90%;
    position: absolute;
}
.ant-upload-list.ant-upload-list-picture-card {
    position: relative;
}

.primary_error {
    color: red;
}

.job_description.job_description {
    background-color: #eaeaea70;
    padding: 1em;
}

.image-gallery-thumbnails-wrapper {
    padding: 30px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .main_frame {
        margin: -8em 1em 0em 1em;
    }
    .react-tel-input .country-list {
        height: 90vh;
        top: -250px;
        max-height: 90vh !important;
        .search-box {
            font-size: 28px !important;
            line-height: 25px !important;
            padding: 3px 8px 5px !important;
            outline: none !important;
            width: 90%;
        }
    }
    
}

.company_reg_detail {
    overflow: auto;
    height: 350px;
}

.download_bg_color {
    background-color: #f1ffd9;
}
admin_side_bar_scroll::-webkit-scrollbar-track {
    border: 1px solid $primarycolor;
    background-color: #d3caca;
}
.admin_side_bar_scroll::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}
.admin_side_bar_scroll::-webkit-scrollbar-thumb {
    background-color: $light_grey;
}

.tps::-webkit-scrollbar-track {
    border: 1px solid $primarycolor;
    background-color: #d3caca;
}
.tps::-webkit-scrollbar {
    height: 6px;
    background-color: #f5f5f5;
}

.tps::-webkit-scrollbar-thumb {
    background-color: $primarycolor;
}

.company_reg_detail::-webkit-scrollbar-track {
    border: 1px solid $primarycolor;
    background-color: #d3caca;
}

.company_reg_detail::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

.company_reg_detail::-webkit-scrollbar-thumb {
    background-color: $primarycolor;
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}

.steps-action {
    margin-top: 24px;
}

@mixin custom_setper($light: true) {
    $steper_color: black;
    @if $light {
        $steper_color: $light_grey;
    } @else {
        $steper_color: $primarycolor;
    }
    padding: 10px;
    border-radius: 25px;
    background-color: $steper_color;
    border: 1px solid $steper_color;
    &:before {
        content: "";
        position: absolute;
        border: 1px solid $steper_color;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 100%;
        transform: scale(1.32);
        z-index: 3;
    }
}

.contract_steper {
    .ant-steps-item-content {
        width: 130px !important;
    }
    .ant-steps-label-vertical .ant-steps-item-icon {
        margin-left: 40px !important;
    }
    .ant-steps-item-wait {
        .ant-steps-icon {
            @include custom_setper($light: true);
        }
    }
    .ant-steps-item-process {
        .ant-steps-icon {
            @include custom_setper($light: true);
        }
        .ant-steps-icon {
            background-color: $primaryBlueColor !important;
            border: 1px solid $primaryBlueColor !important;
        }
        .ant-steps-item-title {
            color: $primaryBlueColor !important;
            font-weight: bold;
        }
        .ant-steps-icon:before {
            border: 1px solid $primaryBlueColor !important;
        }
    }
    .ant-steps-item-finish {
        .ant-steps-icon {
            @include custom_setper($light: false);
        }
        .ant-steps-icon {
            background-color: $primarycolor !important;
            border: 1px solid $primarycolor !important;
        }
        .ant-steps-item-title {
            color: $primarycolor !important;
        }
    }
    .ant-steps-item-active.ant-steps-item-finish {
        .ant-steps-item-title {
            font-weight: bold;
        }
    }
    .ant-steps-item-active.ant-steps-item-wait {
        .ant-steps-item-title {
            color: $primarycolor !important;
            font-weight: bold;
        }
        .ant-steps-icon:before {
            border: 1px solid $primarycolor !important;
        }
    }
    .ant-steps-item-active.ant-steps-item-finish .ant-steps-icon:before {
        border: 1px solid $primarycolor !important;
    }
    .ant-steps-item-tail::after {
        background-color: $light_grey !important;
    }
    .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
        font-size: 15px !important;
        img {
            height: 20px;
            width: 20px;
            object-fit: contain;
        }
    }
}

.milestone_steper {
    .ant-steps-item {
        line-height: 4.5;
    }
    .ant-steps-item-process {
        .ant-steps-item-tail::after {
            background-color: $light_grey !important;
        }
        .ant-steps-icon-dot {
            background-color: $light_grey !important;
            .ant-steps-item-wait {
                .ant-steps-icon {
                    @include custom_setper($light: true);
                }
            }
        }
    }
    .ant-steps-item-finish {
        .ant-steps-item-tail::after {
            background-color: $primarycolor !important;
        }
        .ant-steps-icon-dot {
            background-color: $primarycolor !important;
        }
    }
}
.ant-radio-checked .ant-radio-inner {
    border-color: $primarycolor;
    &:after {
        background-color: $primarycolor;
    }
}

.px_view .ant-upload {
    width: 100%;
    height: 12em;
}

.contract_image {
    padding: 30px 10px !important;
    width: 25%;
    border: 1px solid lightgray;
    img {
        width: -webkit-fill-available;
    }
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.milestone_steper .ant-steps-item-content {
    width: -webkit-fill-available;
}
.imgHolder {
    height: 150px;
    .tags {
        position: absolute;
        color: white;
        background-color: #99d332;
        padding: 0px 8px;
        left: 4px;
        top: 4px;
        max-width: calc(100% - 8px);
    }
    .delete {
        position: absolute;
        color: #fff;
        padding: 5px 8px;
        right: 10px;
        bottom: 8px;
        cursor: pointer;
        z-index: 999;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 5px;
        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.7);
        }
    }
    .edit {
        position: absolute;
        color: #fff;
        padding: 5px 8px;
        right: 55px;
        bottom: 8px;
        cursor: pointer;
        z-index: 999;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 5px;
        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.7);
        }
    }
}

.contract_header_images .ant-upload.ant-upload-select-picture-card {
    height: 0px;
    margin: 0px;
}
.document_section_contract {
    .ant-card-body {
        padding: 10px;
    }
    .ant-card-head {
        display: flex;
    }
    .ant-upload.ant-upload-select-picture-card {
        height: 0px;
        margin: 0px;
        width: auto !important;
    }
    .ant-upload-text {
        display: none;
    }
    .ant-card-head-title,
    .ant-upload-select span {
        padding: 0px !important;
    }
    .ant-card-head-wrapper {
        width: 100%;
    }
}
.contract_images_section {
    &::-webkit-scrollbar-track {
        border: 1px solid $primarycolor;
        background-color: #d3caca;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primarycolor;
    }
}

.multi_currency_panel .ant-collapse-extra {
    margin-top: -20px;
}

.booking_tab_sticky .ant-tabs-bar {
    top: 0px;
    padding-top: 5px;
    background-color: white;
    z-index: 100;
    position: sticky !important;
}
.contract_actions {
    height: 50px !important;
    border-radius: 10px !important;
    span {
        text-transform: uppercase;
        font-size: 1.2em;
    }
}
.contract_actions.ant-btn-background-ghost {
    span {
        color: $primarycolor;
    }
}
.extra_radius_input {
    border-radius: 10px !important;
}
.h-50x {
    height: 50px !important;
}
.h-200x {
    height: 200px !important;
}
.ant-btn-primary {
    color: #fff;
    background-color: $primarycolor !important;
    border-color: $primarycolor !important;
}
.ant-btn-primary {
    text-shadow: none !important;
}
.contract_saved_address_list {
    .ant-list-item {
        border-bottom: 2px solid #e8e8e8 !important;
        border: 2px solid #fff;
    }
    .table-active,
    .table-active > td,
    .table-active > th {
        background-color: #fff !important;
        border: 2px solid !important;
        border-radius: 10px;
        transition: border-color 1s ease;
    }
}

.opacity_0_9 {
    opacity: 0.9 !important;
}
.mt_15_per {
    margin-top: 15% !important;
}
.mb_15_per {
    margin-bottom: 15% !important;
}
.mb_35_per {
    margin-bottom: 40% !important;
}

.box {
    background: white;
}
.box::before {
    content: "";
    position: absolute;
    height: 2%;
    width: 100%;
    background: linear-gradient(90deg, white, #0f2949, white);
    z-index: -1;
    filter: blur(20px);
}
.box::after {
    content: "";
    position: absolute;
    height: 2%;
    width: 100%;
    background: linear-gradient(90deg, white, #0f2949, white);
    z-index: -1;
    filter: blur(20px);
}

@media (max-device-width: 600px) {
    .display-4 {
        font-size: 3rem !important;
    }
    .box::before {
        right: 0%;
    }
    .box::after {
        right: 0%;
    }
}

.top_shadow {
    // box-shadow: -2px -13px 19px 0px #525252;
    // position: absolute;
    padding: 20px;
    background: white;
    z-index: 1;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 50px;
}
.left_shadow {
    // box-shadow: -2px -13px 19px 0px #525252;
    // position: absolute;
    padding: 20px;
    background: white;
    z-index: 1;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 20px;
}

.ReviewCarousel .slick-dots-bottom {
    bottom: -20px;
}
.border_x_1 {
    border-right: 1px solid #efefef !important;
}
.send_link_btn_border {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}
.br_7 {
    border-radius: 7px;
}

.top_br_20 {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

.dot_image {
    position: absolute;
    top: 50%;
    right: 90%;
}

.card_body_top_shadow {
    .ant-card-body::before {
        content: "";
        box-shadow: -1px -11px 16px 2px black;
        width: 100%;
        z-index: 1000;
        position: absolute;
    }
}

.zIndex_1 {
    z-index: 1;
}

.home_banner_bg {
    width: 100%;
    background-image: url("../image/landing_page.webp");
    background-size: cover;
    border-radius: 10px;
}
.br_20 {
    border-radius: 20px !important;
}

.custom-scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* For Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
    border: 2px solid #f1f1f1; /* Padding around the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovered */
}

.image-container {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
}

.image-container::-webkit-scrollbar {
    height: 8px;  // Set the height of the scrollbar
}

.image-container::-webkit-scrollbar-thumb {
    background-color: #888;  // Color of the scrollbar thumb
    border-radius: 4px;  // Rounded corners for the scrollbar thumb
}

.image-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;  // Color of the scrollbar thumb on hover
}

.image-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;  // Color of the scrollbar track
}
