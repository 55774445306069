.sidebardiv {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 7px 7px 1px 0px #bfbfbf40;
  height: fit-content !important;
}

.sidebardiv_que {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 7px 7px 1px 0px #bfbfbf40;
  height: fit-content !important;
}

.addressdiv {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 7px 7px 1px 0px #bfbfbf40;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: 10% 75% 15%;
  cursor: pointer;
}

.sidebardivnew {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 7px 7px 1px 0px #bfbfbf40;
}

.sidebardivnewquestion {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 7px 7px 1px 0px #bfbfbf40;
  height: fit-content;
}

.sidebardivnewquestionnew {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 7px 7px 1px 0px #bfbfbf40;
  height: fit-content;
}

.sidebardivnewquestionreview {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 7px 7px 1px 0px #bfbfbf40;
  height: fit-content;
}

.sidebarheading:hover {
  border: 2px solid #266206;
}

.livelick {
  background-color: #dff3bb;
  padding: 15px;
  color: #3f9d12 !important;
  font-weight: bold;
}

.sidebarheading {
  padding: 15px;
  color: black;
  font-size: 18px !important;
  font-family: "Muli" !important;
  src: url("../../../fonts//muli.regular.ttf") !important;
  border: 2px solid transparent;
}

#myDIV {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 40px;
  margin-top: 30px;
  padding: 0 40px;
}

.select_catdiv {
  padding: 20px;
}

form.nosubmit {
  border: none;
  padding: 0;
}

input.nosubmit {
  width: 100%;
  border: none;
  display: block;
  box-shadow: 4px 4px 4px 4px #bfbfbf40;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.nosubmitdiv {
  display: flex;
  margin-top: 20px;
}

.search_btn {
  background-color: #84cb07;
  border: none;
  padding: 16px;
  color: white;
  width: 20%;
}

.search_btn:hover {
  background-color: #34a108;
  border: none;
  padding: 16px;
  color: white;
  font-weight: bold;
  width: 20%;
}

.category_list {
  margin-top: 20px;
  height: 260px;
  overflow-y: scroll;
}

.category_list::-webkit-scrollbar {
  display: none;
}

.address_list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.cate_name {
  background-color: #f9f7f7;
  padding: 10px 30px 10px 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.cate_name input[type="radio"] {
  margin-right: 10px;
}

.cate_name:hover {
  background-color: #84cb07;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.selected_category {
  background-color: #84cb07;
  padding: 10px 30px 10px 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  box-shadow: 4px 4px 4px 4px #bfbfbf40;
}

.cat_label {
  margin: 0;
  font-weight: 400;
  cursor: pointer;
  font-size: 18px;
}

.btn_grp {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.cancel_btn {
  padding: 5px;
  border: 2px solid #84cb07;
  background-color: white;
  border-radius: 5px;
  width: 30%;
  color: darkgreen;
}

.cancel_btn:hover {
  padding: 5px;
  border: 2px solid #84cb07;
  background-color: #84cb07;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  width: 30%;
}

.postbtn {
  padding: 5px;
  border: 2px solid #84cb07;
  background-color: #84cb07;
  border-radius: 5px;
  color: white;
  width: 30%;
}

.next_btn {
  padding: 5px;
  border: 2px solid #84cb07;
  background-color: #84cb07;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  width: 30%;
}

.next_btn:hover {
  padding: 5px;
  border: 2px solid green;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  width: 30%;
}

.confirm_btn {
  padding: 5px;
  border: 2px solid #84cb07;
  background-color: #84cb07;
  border-radius: 5px;
  color: white;
  width: 30%;
}

.addnew_add {
  color: #84cb07;
  cursor: pointer;
  font-size: 2rem;
}

.g_map {
  height: 200px;
  width: 100%;
}

.input_lable {
  font-weight: bold;
  font-size: 20px;
}

.f_20 {
  font-size: 20px;
}

.f_21 {
  font-size: 20px;
  color: darkgray;
}

.f_26 {
  font-size: 25px;
}

.skipbtn {
  padding: 0px 0px;
  color: #84cb07;
  cursor: pointer;
}

.reviewgroup_grp {
  display: flex;
  justify-content: space-between;
}

.doc_list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
}

.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: linear-gradient(to top right, darkmagenta 0%, hotpink 100%);
  color: #333;
  font-family: "Open Sans", Helvetica, sans-serif;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 260px;
  border-radius: 3px;
  box-shadow: 8px 10px 15px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.title {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #999;
  text-align: center;
}

h1 {
  font-size: 16px;
  font-weight: 300;
  color: #666;
}

.dropzone {
  width: 120px;
  height: 120px;
  border: 1px dashed #999;
  border-radius: 3px;
  text-align: center;
}

.upload-icon {
  margin: 40px;
  font-size: 38px;
}

.upload-input {
  position: relative;
  top: -62px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.btn {
  display: block;
  width: 140px;
  height: 40px;
  background: darkmagenta;
  color: #fff;
  border-radius: 3px;
  border: 0;
  box-shadow: 0 3px 0 0 hotpink;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.btn:hover {
  background: rebeccapurple;
  box-shadow: 0 3px 0 0 deeppink;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  display: none;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal_heading {
  color: #84cb07;
}

.model_img {
  width: 25%;
  margin: 0px 170px;
}

.contibtn_yellow {
  padding: 5px;
  border: 2px solid #84cb07;
  background-color: #84cb07;
  border-radius: 10px;
  color: white;
  width: 50%;
  margin: 20px 115px;
}

.contibtn_delete{
  padding: 5px;
  border: 2px solid red;
  background-color: red;
  border-radius: 10px;
  color: white;
  width: 50%;
  margin: 20px 115px;
}

.contibtn {
  padding: 5px;
  border: 2px solid #34a108;
  background-color: #34a108;
  border-radius: 10px;
  color: white;
  width: 50%;
  margin: 20px 115px;
}

ul {
  list-style: none;
}

.file-upload-wrapper {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-fileupload {
  background: #d3e7ff;
  width: 80%;
  padding: 50px;
  border: 2px dashed #a9e4f1;
  border-radius: 3px;
}

ul {
  list-style: none;
}

.maincontent {
  max-width: 1080px;
  margin: 0 auto;
  /* padding: 50px 0; */
}

.file-upload-wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background: #fff;
  padding: 20px 0;
  border-radius: 4px;
}

.box-fileupload {
  background: #d3e7ff;
  width: 90%;
  padding: 50px;
  border: 3px dashed #8fd9ea;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.file-upload-input {
  display: none;
}

label.file-upload-btn {
  width: 182px;
  height: 120px;
  background-image: url(http://www.pngall.com/wp-content/uploads/2/Upload-PNG-Image-File.png);
  background-size: cover;
  background-position: center center;
  background-origin: border-box;
  background-repeat: no-repeat;
}

.image-previwe {
  width: 90%;
  height: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
}

.image-previwe__image {
  min-width: 129px;
  min-height: 130px;
  border: 2px solid #5c8ac3;
  border-radius: 5px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px;
  position: relative;
  -webkit-box-shadow: 0 13px 6px -8px rgba(0, 0, 0, 0.3),
    0 0 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 13px 6px -8px rgba(0, 0, 0, 0.3), 0 0 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.image-previwe__delete-btn {
  width: 25px;
  height: 25px;
  background: #000000ab;
  color: #fff;
  border-radius: 28px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  z-index: 2;
}

.image-previwe__delete-btn::before,
.image-previwe__delete-btn::after {
  content: "";
  display: block;
  width: 56%;
  height: 2px;
  background: #fff;
  position: absolute;
}

.image-previwe__delete-btn::after {
  transform: rotate(43deg);
}

.image-previwe__delete-btn::before {
  transform: rotate(-40deg);
}

.image-previwe__hover {
  background: #0000008f;
  color: #fff;
  display: inline-flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.4s;
  left: 0;
  right: 0;
  top: 0;
  box-sizing: border-box;
  text-align: center;
}

.image-previwe__hover p {
  font-size: 13px;
  width: 100%;
  padding: 10px;
  word-wrap: break-word;
}

.image-previwe__hover:hover,
.image-previwe__close-btn:hover {
  opacity: 1;
}

.file-upload-wrapper-title {
  width: 92%;
  min-height: 50px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.file-upload-wrapper-title h4 {
  display: inline-block;
  font-size: 20px;
  padding: 10px 0px 6px 0;
  font-weight: 200;
}

.file-upload-wrapper-title hr {
  width: 21%;
  display: inline-block;
  margin: 6px 0;
}

.file-upload-wrapper-title__btn {
  background-color: #4099ff;
  border-color: #4099ff;
  border: none;
  padding: 12px 20px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  border-radius: 2px;
}

.box-fileupload__lable {
  font-size: 20px;
  margin: 10px 0;
  color: #1471ad;
}

.error-wrapper {
  margin: 10px;
  width: 90%;
}

.error-format {
  background: #ff00003b;
  padding: 15px 10px;
  border-radius: 5px;
  border: 2px solid #f6343b;
  color: #b00707;
  margin: 10px;
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: transparent;
  color: red;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.marker:hover {
  z-index: 1;
}

.textarea_ques {
  border: 1px solid lightgray;
  border-radius: 10px;
}

button,
input {
  cursor: pointer;
}

.btn_grp_heading {
  display: flex;
  justify-content: space-between;
}

.address_listnew {
  padding: 0px 0px;
}
.address_listnew .cat_label {
  margin: 5px 0px 0px 10px;
}
.image-area {
  position: relative;
  width: 50%;
}

.image-area img {
  width: 120px;
  height: 120px;
}

.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -50px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

.remove-image:hover {
  background: #e54e4e;
  padding: 2px 6px 3px;
  top: -10px;
  right: -50px;
  text-decoration: none;
}

.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -50px;
  text-decoration: none;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #84cb07;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
  font-size: 22px;
}

iframe {
  position: absolute !important;
}

@media (max-width: 728px) {
  .sidebardiv {
    display: none;
  }

  .select_catdiv {
    padding: 20px;
    width: 100%;
  }

  .sidebardivnew {
    width: 140%;
  }

  .sidebardiv_que {
    display: none;
  }

  .btn_grp_heading {
    display: contents;
  }

  .address_list {
    display: contents;
  }

  .sidebardivnewquestion {
    width: 160%;
  }

  .sidebardivnewquestionnew {
    width: 160%;
  }

  .sidebardivnewquestionreview {
    width: 107%;
  }

  .sidebardivnewquestionreview .f_26 {
    font-size: 18px;
  }

  .sidebardivnewquestionreview .f_21 {
    font-size: 18px;
  }

  .doc_list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    width: 10%;
  }

  .postbtn {
    font-size: 15px;
  }

  .remove-image {
    display: none;
    position: absolute;
    top: -10px;
    right: -70px;
    border-radius: 10em;
    padding: 2px 6px 3px;
    text-decoration: none;
    font: 700 21px/20px sans-serif;
    background: #555;
    border: 3px solid #fff;
    color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
  }

  .remove-image:hover {
    background: #e54e4e;
    padding: 2px 6px 3px;
    top: -10px;
    right: -70px;
    text-decoration: none;
  }

  .remove-image:active {
    background: #e54e4e;
    top: -10px;
    right: -70px;
    text-decoration: none;
  }
}

.backbtn {
  font-size: 25px;
  cursor: pointer;
}

.backname {
  cursor: pointer;
  font-family: "Muli" !important;
  src: url("../../../fonts/muli.regular.ttf") !important;
}

.searchnamelist {
  cursor: pointer;
}

.test_modal {
  border-radius: 50px;
  color: red;
  border-radius: 20px;
  background-color: #1471ad;
}
